var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("mew-address-select", {
        ref: "addressSelect",
        attrs: {
          "resolved-addr": _vm.addressOnly,
          hint: _vm.nameOnly,
          "copy-tooltip": _vm.$t("common.copy"),
          "save-tooltip": _vm.$t("common.save"),
          "enable-save-address": _vm.enableSave,
          "show-save": _vm.enableSave,
          label: _vm.addrLabel,
          items: _vm.addressBookWithMyAddress,
          placeholder: _vm.$t("sendTx.enter-addr"),
          "success-toast": _vm.$t("sendTx.success.title"),
          "is-valid-address": _vm.isValidAddress,
          "show-copy": _vm.isValidAddress,
          "error-messages": _vm.errorMessages,
        },
        on: { saveAddress: _vm.toggleOverlay, input: _vm.setAddress },
      }),
      _c(
        "mew-overlay",
        {
          attrs: {
            footer: _vm.footer,
            title: _vm.$t("interface.address-book.add-addr"),
            "show-overlay": _vm.addMode,
            close: _vm.toggleOverlay,
            "content-size": "xlarge",
          },
        },
        [
          _c("address-book-add-edit", {
            attrs: { "to-address": _vm.inputAddr, mode: "add" },
            on: { back: _vm.toggleOverlay },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }